var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "mapping-selector d-flex justify-end" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("p", [_vm._v(_vm._s(_vm.$t("Normal Group")))]),
            _c("v-select", {
              staticClass: "form-select ml-2",
              attrs: {
                outlined: "",
                dense: "",
                "hide-details": "",
                solo: "",
                placeholder: "" + _vm.$t("Choose the group"),
                items: _vm.groupItems,
                "item-text": "groupName",
                "item-value": "groupName"
              },
              model: {
                value: _vm.selectedGroup,
                callback: function($$v) {
                  _vm.selectedGroup = $$v
                },
                expression: "selectedGroup"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01",
          attrs: {
            headers: _vm.templateMappingHeaders,
            items: _vm.tableItems,
            page: _vm.page,
            "hide-default-footer": true,
            "items-per-page": -1,
            "item-key": "priority",
            "show-select": "",
            "fixed-header": "",
            height: "528"
          },
          on: {
            "update:page": function($event) {
              _vm.page = $event
            }
          },
          model: {
            value: _vm.selectedRow,
            callback: function($$v) {
              _vm.selectedRow = $$v
            },
            expression: "selectedRow"
          }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")])
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "innerButton" },
        [
          _c("div", { staticClass: "pageInfo" }, [
            _vm._v(_vm._s(_vm.pageInfoText))
          ]),
          _c(
            "div",
            { staticClass: "mt-2 mb-2" },
            [
              _c("v-btn", { attrs: { text: "", icon: "" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-move-top.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.moveRow("top")
                    }
                  }
                })
              ]),
              _c("v-btn", { attrs: { text: "", icon: "" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-move-up.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.moveRow("up")
                    }
                  }
                })
              ]),
              _c("v-btn", { attrs: { text: "", icon: "" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-move-down.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.moveRow("down")
                    }
                  }
                })
              ]),
              _c("v-btn", { attrs: { text: "", icon: "" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/ico-move-bottom.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.moveRow("bottom")
                    }
                  }
                })
              ])
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "400" },
              on: { "click:outside": _vm.closeModal },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "btn",
                              attrs: { text: "", disabled: _vm.buttonDisabled }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v(_vm._s(_vm.$t("New")))]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialogSave,
                callback: function($$v) {
                  _vm.dialogSave = $$v
                },
                expression: "dialogSave"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "popup add_store_popup" },
                [
                  _c("h3", { staticClass: "page-title-bar" }, [
                    _c("i", { staticClass: "ico ico-template" }),
                    _vm._v(_vm._s(_vm.$t("NEW MAPPING")))
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "tartgetField",
                            staticClass: "form-select",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Target Field"),
                              placeholder:
                                "" + _vm.$t("Select the target field"),
                              items: _vm.targetFieldItems,
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.SaveCondition.tartgetField,
                              callback: function($$v) {
                                _vm.$set(_vm.SaveCondition, "tartgetField", $$v)
                              },
                              expression: "SaveCondition.tartgetField"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            ref: "expectedValue",
                            staticClass: "form-input",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Expected Value"),
                              placeholder:
                                "" + _vm.$t("Input the expected value"),
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.SaveCondition.expectedValue,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.SaveCondition,
                                  "expectedValue",
                                  $$v
                                )
                              },
                              expression: "SaveCondition.expectedValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "linkedTemplateGroup",
                            staticClass: "form-select",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "" + _vm.$t("Template Group"),
                              placeholder: "" + _vm.$t("Template Group"),
                              items: _vm.groupItems,
                              "item-text": "groupName",
                              "item-value": "groupName",
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.SaveCondition.linkedTemplateGroup,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.SaveCondition,
                                  "linkedTemplateGroup",
                                  $$v
                                )
                              },
                              expression: "SaveCondition.linkedTemplateGroup"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.AddSave()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Save")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogSave = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "btn ml-1",
              attrs: { text: "", disabled: _vm.deleteDisabled },
              on: {
                click: function($event) {
                  return _vm.RemoveRow()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Delete")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "btn ml-1",
              attrs: { text: "", disabled: _vm.buttonDisabled },
              on: {
                click: function($event) {
                  return _vm.Save()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }