var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-template" }),
        _vm._v(_vm._s(_vm.$t("Template Mapping")))
      ]),
      _c(
        "v-tabs",
        {
          staticClass: "tab-group mt-10",
          attrs: {
            height: "45",
            "hide-slider": true,
            "active-class": "selected"
          },
          model: {
            value: _vm.selectedTaps,
            callback: function($$v) {
              _vm.selectedTaps = $$v
            },
            expression: "selectedTaps"
          }
        },
        [
          _vm._l(_vm.tabsItem, function(value) {
            return _c(
              "v-tab",
              {
                key: value,
                on: {
                  click: function($event) {
                    return _vm.clickTap(value)
                  },
                  mouseenter: function() {
                    _vm.active = value === _vm.tabsItem[_vm.tabsItem.length - 1]
                  },
                  mouseleave: function() {
                    _vm.active = false
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(value) + " " + _vm._s(_vm.$t("Page")) + " "
                ),
                value !== 1 &&
                value === _vm.tabsItem[_vm.tabsItem.length - 1] &&
                _vm.active
                  ? _c(
                      "v-icon",
                      {
                        staticStyle: {
                          color: "red",
                          "margin-bottom": "20px",
                          "margin-right": "-20px"
                        },
                        attrs: { right: "" },
                        on: {
                          click: function($event) {
                            return _vm.removeTab(value)
                          }
                        }
                      },
                      [_vm._v("mdi-close-circle")]
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          _c(
            "v-btn",
            {
              attrs: { text: "", icon: "", height: "45" },
              on: {
                click: function($event) {
                  return _vm.addTap()
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/ico-plus.png"),
                  alt: "추가"
                }
              })
            ]
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.selectedTaps,
                callback: function($$v) {
                  _vm.selectedTaps = $$v
                },
                expression: "selectedTaps"
              }
            },
            _vm._l(_vm.tabsItem, function(value) {
              return _c(
                "v-tab-item",
                {
                  key: value,
                  staticClass: "tab-cont",
                  attrs: { transition: false, "reverse-transition": false }
                },
                [
                  _c("TemplateMappingTab", {
                    attrs: {
                      tapNumber: value,
                      buttonDisabled: _vm.buttonDisabled
                    },
                    on: { changedSave: _vm.changedSaveState }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.dialogConfirm,
            callback: function($$v) {
              _vm.dialogConfirm = $$v
            },
            expression: "dialogConfirm"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.confirmMessageText) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.moveTap("Yes")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.moveTap("No")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("No")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "300" },
              model: {
                value: _vm.messageDialog,
                callback: function($$v) {
                  _vm.messageDialog = $$v
                },
                expression: "messageDialog"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "popup add_store_popup",
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.messageText) }
                        })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "", "max-width": "100" },
                          on: {
                            click: function($event) {
                              return _vm.forceDeleteTab()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("OK")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "", "max-width": "100" },
                          on: {
                            click: function($event) {
                              _vm.messageDialog = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "btn bg mt-5",
                          attrs: { text: "", disabled: _vm.buttonDisabled }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(_vm._s(_vm.$t("Apply")))]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialogApply,
            callback: function($$v) {
              _vm.dialogApply = $$v
            },
            expression: "dialogApply"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-template" }),
                _vm._v(_vm._s(_vm.$t("Apply")))
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "form-input",
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            readonly: "",
                                            label: _vm.$t("Date"),
                                            clearable: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    _c("img", {
                                                      attrs: {
                                                        width: "16",
                                                        height: "16",
                                                        src: require("@/assets/img/ico-calendar.png")
                                                      }
                                                    })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.selectedDate,
                                            callback: function($$v) {
                                              _vm.selectedDate = $$v
                                            },
                                            expression: "selectedDate"
                                          }
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.menu1,
                            callback: function($$v) {
                              _vm.menu1 = $$v
                            },
                            expression: "menu1"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              locale:
                                _vm.languageCodes[
                                  _vm.$store.getters[
                                    "dataStore/GET_CURRENT_LANGUAGE"
                                  ]
                                ],
                              min: _vm.minDate(),
                              "no-title": ""
                            },
                            on: {
                              input: function($event) {
                                _vm.menu1 = false
                              },
                              change: _vm.getTimeItems
                            },
                            model: {
                              value: _vm.selectedDate,
                              callback: function($$v) {
                                _vm.selectedDate = $$v
                              },
                              expression: "selectedDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        ref: "selectedTime",
                        staticClass: "form-select",
                        attrs: {
                          label: _vm.$t("Time"),
                          placeholder: "" + _vm.$t("Choose the Time"),
                          items: _vm.timeItems,
                          clearable: "",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.selectedTime,
                          callback: function($$v) {
                            _vm.selectedTime = $$v
                          },
                          expression: "selectedTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.ApplySave()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogApply = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }